import { RouteAuthenticationRequirement, RouteItem } from '@cksoftware/react-base';
import { NarrowLayout } from '../components/layout/narrowLayout';
import { AppRoles } from '../constants/appRoles';
import { ApplicationRoutes } from '../constants/appRoutes';

export const InspectionAdminRoutes: RouteItem = {
  text: 'Inspection Admin',
  authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
  allowedRoles: [AppRoles.UserAdmin, AppRoles.Admin],
  layout: <NarrowLayout />,
  subItems: [
    {
      text: 'Inspection Reports',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.inspectionAdmin.view,
      allowedRoles: [AppRoles.UserAdmin],
      async lazy() {
        const component = await import('../features/inspectionReportAdmin/InspectionReportAdmin');
        return { Component: component.InspectionReportAdmin };
      }
    },

    {
      text: 'Lockouts',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.inspectionAdmin.lockouts,
      allowedRoles: [AppRoles.UserAdmin],
      async lazy() {
        const component = await import('../features/lockoutAdmin/lockoutAdmin');
        return { Component: component.LockoutAdmin };
      }
    },
    {
      text: 'AQL Tables',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.inspectionAdmin.aql,
      allowedRoles: [AppRoles.UserAdmin],
      async lazy() {
        const component = await import('../features/aqlAdmin/aqlAdmin');
        return { Component: component.AqlAdmin };
      }
    },
    {
      text: 'Forms',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.inspectionAdmin.forms,
      allowedRoles: [AppRoles.UserAdmin],
      async lazy() {
        const component = await import('../features/formAdmin/formAdmin');
        return { Component: component.FormAdmin };
      }
    },
    {
      text: 'Select Lists',
      authenticationRequirement: RouteAuthenticationRequirement.Authenticated,
      path: ApplicationRoutes.inspectionAdmin.selectList,
      allowedRoles: [AppRoles.UserAdmin],
      async lazy() {
        const component = await import('../features/selectListAdmin/selectListAdmin.tsx');
        return { Component: component.SelectListAdmin };
      }
    }
  ]
};
